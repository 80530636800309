<template>
  <div class="order-detail-wrapper" style="margin: 20px auto">
    <div class="container" v-loading="loading">
      <el-row gutter=20>
        <el-col span=5>
          <memberleft :activeIndex="leftPannelSelected" />
        </el-col>
        <el-col span=19>
          <div class="passenger">
            <div class="info-panel" v-if="order.orderStatus != undefined">
              <div class="info-panel">
                <div class="booked-ticket">
                  <div class="booked-title">
                    <div class="title">订单信息</div>
                  </div>
                  <div class="booked-ticket-body">
                    <div class="passenger-info no-padding">
                      <div class="book-panel" v-if="order.orderStatus == 'IW'">
                        <!-- <div
                          @click="gotoRefund(orderNo)"
                          v-if="order.orderStatus=='IW' && showRefund"
                        >退票</div> -->
                      </div>
                      <div class="book-panel" v-if="order.orderStatus == 'WP'">
                        <div @click="endorse(orderNo)">支付</div>
                      </div>
                      <el-form
                        class="passenger-input-info"
                        style="margin-top: 10px"
                        label-position="top"
                      >
                        <div>
                          <div>
                            <span class="item-title">订单状态</span>
                            <span class="item-value">{{
                              order.orderStatusDesc
                            }}</span>
                          </div>
                          <div>
                            <span class="item-title">订单号</span>
                            <span class="item-value">{{ order.orderNo }}</span>
                          </div>
                          <div>
                            <span class="item-title">预订日期</span>
                            <span class="item-value">{{
                              order.createTime | formatDate
                            }}</span>
                          </div>
                          <div>
                            <span class="item-title">总金额</span>
                            <div
                              class="item-value"
                              style="display: inline-block"
                            >
                              <span class="order-price"
                                >¥{{ order.orderAmount }}</span
                              >
                              <span style="cursor: pointer" @click="showDetail"
                                >查看明细</span
                              >
                              <img
                                @click="showDetail"
                                v-if="!detailVisible"
                                class="order-drop"
                                src="../../assets/drop.png"
                                alt=""
                              />
                              <img
                                @click="showDetail"
                                v-else
                                style="transform: rotate(180deg)"
                                class="order-drop"
                                src="../../assets/drop.png"
                                alt=""
                              />
                              <div
                                class="detail-content fee-panel-group"
                                v-if="detailVisible"
                              >
                                <div
                                  v-for="fare in fareData"
                                  v-bind:key="fare.title"
                                >
                                  <ul class="list">
                                    <li>
                                      <span>{{ fare.title }}</span>
                                      <span
                                        >¥{{ fare.price }} *
                                        {{ fare.passCount }}</span
                                      >
                                    </li>
                                    <li>
                                      <span>机建+燃油</span>
                                      <span
                                        >¥{{ fare.tax }} *
                                        {{ fare.passCount }}</span
                                      >
                                    </li>
                                  </ul>
                                </div>
                                <ul class="list">
                                  <li>
                                    <span>航班保险</span>
                                    <span>¥0</span>
                                  </li>
                                  <li>
                                    <span>保险优惠减免</span>
                                    <span>¥0</span>
                                  </li>
                                  <li>
                                    <span>
                                      优惠减免
                                      <i class="iconfont icon-question"></i>
                                    </span>
                                    <span>¥0</span>
                                  </li>
                                  <li>
                                    <span>里程抵扣</span>
                                    <span>¥0</span>
                                  </li>
                                  <li>
                                    <span>邮寄费用</span>
                                    <span>¥0</span>
                                  </li>
                                </ul>
                                <div class="footer">
                                  <!-- <span>订单总金额</span> -->
                                  <span>¥{{ order.orderAmount }}</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </el-form>
                    </div>
                  </div>
                </div>
                <div class="booked-ticket">
                  <div class="booked-title">
                    <div class="title">行程信息</div>
                  </div>
                  <div class="booked-ticket-body">
                    <div class="passenger-info no-padding">
                      <div class="form-title">
                        <div></div>
                        <div></div>
                      </div>
                      <el-form
                        class="passenger-input-info"
                        style="margin-top: 10px"
                        label-position="top"
                      >
                        <div>
                          <!-- <span class="item-title">航班信息</span> -->
                          <span class="item-value">
                            <div>
                              <div class="fee-panel__inner">
                                <div
                                  class="booked-ticket-body"
                                  v-for="(item, idx) in order.segments"
                                  :key="idx"
                                >
                                  <div class="booked-flight-type">
                                    <p>
                                      <span>{{
                                        item.segmentType == "GO" ||
                                        item.journeyType == 1
                                          ? "去程"
                                          : "回程"
                                      }}</span>
                                    </p>
                                  </div>
                                  <div
                                    class="fee-panel-top"
                                    style="display: flex"
                                  >
                                    <img
                                      style="width: 26px; height: 14px"
                                      src="../../assets/logo-small.png"
                                      alt=""
                                    />
                                    <span>{{ item.marketFlightNo }}</span>
                                    <span>{{
                                      item.depDatetime | formatDate
                                    }}</span>
                                    <span>空客{{ item.aircraftStyle }}</span>
                                    <!-- 有无餐食 -->
                                    <span>
                                      {{
                                        item.meal ? '有餐食' : '无餐食'
                                      }}
                                    </span>
                                    <span>{{ item.cabinLabel }}</span>
                                  </div>
                                  <div class="booked-ticket-panel">
                                    <div class="booked-flight-dep">
                                      <p>
                                        <span
                                          style="
                                            margin-left: 4px;
                                            font-size: 20px;
                                          "
                                          >{{
                                            item.depDatetime | formatTime
                                          }}</span
                                        >
                                      </p>
                                      <p>
                                        {{ item.depAirportName
                                        }}{{ item.depAirportTerm }}
                                      </p>
                                    </div>
                                    <div class="flight-main-item during">
                                      <p style="width: 220px; padding: 0 20px">
                                        <span class="during-line"></span>
                                        <img
                                          class="during-air"
                                          src="../../assets/pass-air.png"
                                          alt=""
                                        />
                                      </p>
                                      <p style="color: #aaa; margin-top: 15px">
                                        {{
                                          diffMinutes(
                                            item.depDatetime,
                                            item.arrDatetime
                                          ) | formatDateDuring
                                        }}
                                      </p>
                                    </div>
                                    <div
                                      class="booked-flight-arr"
                                      style="margin-top: 20px"
                                    >
                                      <p
                                        style="
                                          margin-left: 4px;
                                          font-size: 20px;
                                        "
                                      >
                                        {{ item.arrDatetime | formatTime }}

                                        <span class="more-tip">
                                          {{
                                            overRunDays(
                                              item.depDatetime,
                                              item.arrDatetime
                                            )
                                          }}
                                        </span>
                                      </p>
                                      <p>
                                        {{ item.arrAirportName
                                        }}{{ item.arrAirportTerm }}
                                      </p>
                                    </div>
                                    <div
                                      class="form-label"
                                      style="margin-left: 100px"
                                    >
                                      <el-checkbox
                                        @change="segAndPassChange"
                                        v-model="item.checked"
                                      ></el-checkbox>
                                    </div>
                                    <div
                                      class="booked-flight-rule"
                                      v-show="showRule"
                                    >
                                      <el-popover
                                        class="popover"
                                        ref="pop"
                                        trigger="hover"
                                      >
                                        <popover-content
                                          :cabinUnit="flightUnit"
                                        />
                                        <div slot="reference" class="flex">
                                          <img src="../../assets/warn.png" />
                                          退改签规则
                                        </div>
                                      </el-popover>
                                    </div>
                                    <div></div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </span>
                        </div>
                      </el-form>
                    </div>
                  </div>
                </div>
                <div class="booked-ticket">
                  <div class="booked-title">
                    <div class="title">乘机人信息</div>
                  </div>
                  <div class="booked-ticket-body">
                    <div class="passenger-info no-padding">
                      <el-form
                        class="passenger-input-info"
                        label-position="top"
                      >
                        <div
                          style="display: flex"
                          v-for="(pass, idx) in order.passengers"
                          v-bind:key="pass.certNo"
                        >
                          <div class="item-index">{{ idx + 1 }}</div>
                          <div class="order-pass-item">
                            <div>
                              <span class="item-title">{{
                                pass.type | formatType
                              }}</span>
                              <span class="item-value">{{ pass.name }}</span>
                            </div>
                            <div>
                              <span class="item-title">{{
                                pass.certType | formatCertType
                              }}</span>
                              <span class="item-value">{{ pass.certNo }}</span>
                            </div>
                            <div>
                              <span class="item-title">手机号</span>
                              <span class="item-value">{{
                                order.contact.mobile | phoneStash
                              }}</span>
                            </div>
                            <div v-if="pass.tickets[0].ticketNo">
                              <span class="item-title">票号</span>
                              <span class="item-value">{{
                                pass.tickets[0].ticketNo
                              }}
                               <span class="ticketStatus">{{pass.tickets[0].ticketStatus | formatActualStatus}}</span>
                              </span>
                            </div>
                          </div>
                          <div style="margin-left: 50px">
                            <div class="form-label">
                              <el-checkbox
                                @change="segAndPassChange"
                                v-model="pass.checked"
                                v-if="
                                  pass.tickets[0].ticketStatus == 'OPENFORUSE'
                                "
                              ></el-checkbox>
                              <el-checkbox
                                @change="segAndPassChange"
                                disabled
                                v-model="pass.checked"
                                v-if="
                                  pass.tickets[0].ticketStatus != 'OPENFORUSE'
                                "
                              ></el-checkbox>
                            </div>
                          </div>
                        </div>
                      </el-form>
                    </div>
                  </div>
                </div>

                <div class="booked-ticket">
                  <div class="booked-title">
                    <div class="title">
                      退票原因
                    </div>
                  </div>
                  <div class="booked-ticket-body">
                    <div class="tip">请选择退票原因</div>
                    <span>
                        <el-radio-group
                          v-model="refundFeeRequest.refundType"
                          @change="segAndPassChange"
                        >
                          <el-radio label="UNCONSTRAINT">自愿退</el-radio>
                          <el-radio label="CONSTRAINT">非自愿退</el-radio>
                        </el-radio-group>
                      </span>
                      <span v-if="refundFeeRequest.refundType == 'CONSTRAINT'">
                        <el-radio-group
                          class="type-radio"
                          v-model="refundFeeRequest.subRefundType"
                          @change="constraintChange"
                        > 
                          <el-radio label="CANCEL">取消</el-radio>
                          <el-radio label="DELAY">延误</el-radio>
                          <el-radio label="ILL">病退</el-radio>
                          <el-radio label="EPIDEMIC">疫情</el-radio>
                          <el-radio label="OTHER">其他</el-radio>
                        </el-radio-group>
                      </span>
                    <div class="passenger-info no-padding">
                      <el-form
                        class="passenger-input-info"
                        label-position="top"
                        v-if="refundFeeRequest.subRefundType == 'OTHER'"
                      >
                        <el-input
                          type="textarea"
                          :rows="2"
                          placeholder="请输入退票原因"
                          v-model="refundFeeRequest.refundReason"
                        >
                        </el-input>
                      </el-form>
                    </div>
                    <div
                      style="line-height:25px;padding: 10px 20px;color: #666;"
                      v-html="refundTips"
                    ></div>
                  </div>
                </div>

                <div class="booked-ticket">
                  <div class="booked-title">
                    <div class="title">
                      退还金额：
                    </div>
                  </div>
                  <div class="passenger-info no-padding">
                            <span class="item-title">预计退还</span>
                            <div
                              class="item-value"
                              style="display: inline-block"
                            >
                              <span class="order-price"
                              v-if="applyRefundAmount"
                                >¥{{ applyRefundAmount }}</span
                              >
                              <span v-else class="order-price">--</span>
                              <span style="cursor: pointer" @click="detailRefundVisible=!detailRefundVisible"
                                >查看明细</span
                              >
                              <img
                                @click="detailRefundVisible=!detailRefundVisible"
                                v-if="!detailRefundVisible"
                                class="order-drop"
                                src="../../assets/drop.png"
                                alt=""
                              />
                              <img
                                @click="detailRefundVisible=!detailRefundVisible"
                                v-else
                                style="transform: rotate(180deg)"
                                class="order-drop"
                                src="../../assets/drop.png"
                                alt=""
                              />
                              <div
                                class="detail-content fee-panel-group"
                                v-if="detailRefundVisible"
                              >
                                <ul class="list">
                                  <li>
                                    <span>预计退还</span>
                                    <span v-if="applyRefundAmount">¥{{applyRefundAmount}}</span>
                                    <span v-else>--</span>
                                  </li>
                                  <li>
                                    <span>实际支付</span>
                                    <span v-if="orderAmount">¥{{orderAmount}}</span>
                                    <span v-else>--</span>
                                  </li>
                                  <li>
                                    <span>退票费</span>
                                    <span v-if="refundFee">-¥{{refundFee}}</span>
                                    <span v-else>--</span>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                </div>
                <div>
                  <el-button
                    type="danger"
                    v-loading="refundLoading"
                    v-if="order.orderStatus == 'IW'"
                    style="width: 100%; margin: 10px 0"
                    @click="refundTicketHandler"
                    >确认退票</el-button
                  >
                </div>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
    <!-- <RefundDialog @close="refundVisible = false" :visible='refundVisible' :orderNo='orderNo' :fareData="fareData" /> -->
  </div>
</template>
  <script>
import { orderDetail, refundAction,refundFeeAction, queryDict } from "@/api/newsapi";
import moment from "moment";
import memberleft from "../../components/MemberLeft";
// import RefundDialog from "../../components/Refund";

export default {
  data() {
    return {
      order: {},
      fareData: [],
      orderNo: "",
      showRefund: false,
      intervelTime: "",
      loading: true,
      applyRefundAmount: 0,
      refundFee:0,
      orderAmount:0,
      refundLoading:false,
      refundFeeLoading:false,
      detailVisible: false,
      detailRefundVisible: false,
      leftPannelSelected: "orderlist",
      refundVisible: false, // 退票
      selectSegCount: 0,
      selectPassCount: 0,
      refundFeeRequest: { refundType: "UNCONSTRAINT", refundReason: "", subRefundType: 'CANCEL'},
      options: [
        { key: "WP", label: "等待支付", value: "WP" },
        { key: "PF", label: "支付失败", value: "PF" },
        { key: "PT", label: "待出票", value: "PT" },
        { key: "II", label: "出票中", value: "II" },
        { key: "IW", label: "出票完成", value: "IW" },
        { key: "DF", label: "出票失败", value: "DF" },
        { key: "XX", label: "订单取消", value: "XX" },
      ],
      refundTips: ''
    };
  },
  components: { memberleft },
  mounted() {
    this.orderNo = this.$route.params.orderNo;
    //console.log(this.orderNo+" "+this.order.payTimeLimit);
    this.orderDetailHandler();
    this.getDicConfigByKey('FLIGHT_REFUND_INSTRUCTIONS');
  },
  filters: {
    formatDate(timestamp) {
      return moment(timestamp).format("YYYY-MM-DD");
    },
    formatType(type) {
      let typeDesc = "";
      switch (type) {
        case "ADT":
          typeDesc = "成人";
          break;
        case "CHD":
          typeDesc = "儿童";
          break;
        case "INF":
          typeDesc = "儿童";
          break;
        default:
          typeDesc = "";
          break;
      }
      return typeDesc;
    },
    formatCertType(certType) {
      let certTypeDesc = "";
      switch (certType) {
        case "NI":
          certTypeDesc = "身份证";
          break;
        case "PP":
          certTypeDesc = "护照";
          break;
        default:
          certTypeDesc = "";
          break;
      }
      return certTypeDesc;
    },
    formatActualStatus(actualStatus) {
      var statusDesc = "";
      switch (actualStatus) {
        case "NEW":
          statusDesc = "未出票";
          break;
        case "OPENFORUSE":
          // if(this.order.orderStatus=='IW'){
          statusDesc = "已出票";
          // }
          // else{
          //  statusDesc="";
          // }
          break;
        case "EXCHANGE":
          statusDesc = "改期或升舱";
          break;
        case "TVOID":
          statusDesc = "已作废";
          break;
        case "TREFUNDED":
          statusDesc = "已退票";
          break;
        case "CHECKEDIN":
          statusDesc = "已办理值机";
          break;
        case "LIFTORBOARED":
          statusDesc = "客票已使用";
          break;
        case "USEDORFLOWN":
          statusDesc = "客票已使用";
          break;
        case "SUSPENDED":
          statusDesc = "已挂起";
          break;
        case "EXCHANGED":
          statusDesc = "已换开";
          break;
        default:
          statusDesc = "未出票";
          break;
      }

      return statusDesc;
    },
    formatTime(timestamp) {
      return moment(timestamp).format("HH:mm");
    },
    formatDateDuring(mss) {
      var hoursRound = Math.floor(mss / 60);
      var minutes = parseInt(mss % 60);
      return hoursRound + "h" + minutes + "m";
    },
  },
  methods: {
    async getDicConfigByKey(key) {
      queryDict({ key: key }).then(res => {
        if (res.data) {
          this.refundTips = res.data.replace(/\\n/g, "<br/>")
        }
      });
    },
    segAndPassChange() {
      this.refundFeeRequest.orderNo = this.orderNo;
      this.refundFeeRequest.refundSource = "INTERFACE";
      this.refundFeeRequest.contactName = this.order.contact.name;
      this.refundFeeRequest.contactMobile = this.order.contact.mobile;
      this.refundFeeRequest.segments = [];
      this.refundFeeRequest.passengers = [];
      this.selectSegCount = 0;
      this.selectPassCount = 0;
      this.refundFeeRequest.refundReason = "";
      this.refundFeeRequest.subRefundType = "CANCEL";
      for (
        var segIndex = 0;
        segIndex < this.order.segments.length;
        segIndex++
      ) {
        if (this.order.segments[segIndex].checked) {
          this.selectSegCount++;
          console.log("航段数：" + this.selectSegCount);
          this.refundFeeRequest.segments.push({
            depAirport: this.order.segments[segIndex].depAirport,
            arrAirport: this.order.segments[segIndex].arrAirport,
          });
        }
      }

      for (
        var passIndex = 0;
        passIndex < this.order.passengers.length;
        passIndex++
      ) {
        if (this.order.passengers[passIndex].checked) {
          this.selectPassCount++;
          console.log("乘机人数：" + this.selectPassCount);
          this.refundFeeRequest.passengers.push({
            name: this.order.passengers[passIndex].name,
            certNo: this.order.passengers[passIndex].certNo,
            certType: this.order.passengers[passIndex].certType,
          });
        }
      }

      if (
        this.selectSegCount > 0 &&
        this.selectPassCount > 0 &&
        this.refundFeeRequest.refundType.length > 0
      ) {
        if (this.refundFeeRequest.refundReason.length <= 0) {
          //this.refundFeeRequest.refundReason="请输入退票原因";
        }
        this.refundTicketFeeHandler();
      }
      console.log(this.refundFeeRequest);
    },
    refundTicketFeeHandler(){
      var that=this;
      that.refundFeeLoading=true;
      refundFeeAction({t:this.refundFeeRequest}).then(res=>{
         that.refundFeeLoading=false;
          if(res.success){
              this.applyRefundAmount=res.data.applyRefundAmount;
              this.refundFee=res.data.refundFee;
              this.orderAmount = res.data.orderAmount;
          }
      });
    },
    refundTicketHandler() {
      console.log(this.refundFeeRequest);
      if(!this.refundFeeRequest.refundReason){
        // 默认原因: 自愿退 && 非自愿
        if(this.refundFeeRequest.refundType == 'UNCONSTRAINT'){
          this.refundFeeRequest.refundReason = '自愿退'
          this.refundFeeRequest.subRefundType = "";
        }else{
          this.refundFeeRequest.refundReason = this.refundFeeRequest.subRefundType == 'OTHER' ? '' : this.transSubType(this.refundFeeRequest.subRefundType);
        }
      }
      if (this.selectSegCount == 0 || this.selectPassCount == 0) {
        this.$message.error("请先选择退票的行程及乘机人信息，再进行退票");
        return;
      }

      if (this.refundFeeRequest.refundReason.length <= 0) {
        this.$message.error("请输入退票原因");
        return;
      }
      let  tips = '点击确认后机票将不可用，确认退票吗？';
      if(this.refundFeeRequest.refundType != 'UNCONSTRAINT'){
        tips='如不符合非自愿退票规定，将按照自愿退票规定收取相应退票手续费。退票提交后不可恢复，请确认是否提交退票？'
      }
      this.$confirm(tips, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.doRefundRequest();
      }).catch(() => {
      });
    },
    doRefundRequest(){
      this.refundLoading = true;
      refundAction({ t: this.refundFeeRequest }).then((res) => {
        this.refundLoading = false;
        if (res.success) {
          this.applyRefundAmount = res.data.applyRefundAmount;
          this.refundFee = res.data.refundFee;
          if ("SUBMIT_FAIL,AUDIT_FAIL,AUDIT_ONE_REJECT".indexOf(res.data.refundStatus) > -1) {
            this.$message.error("退票提交失败，状态信息：" + res.data.refundStatus);
          } else {
            this.$message({
              message: "退票提交成功，等待后台审核",
              type: "success",
            });
            this.$router.push({ path: "/member/orderlist", query: { t: 1 } });
          }
        } else {
          this.$message.error("退票提交失败，错误信息：" + res.errorMessage);
        }
    });
    },
    reloadPage() {
      window.location.reload();
      //this.window. reload();
    },
    payHandler() {
      console.log("支付检测");
      window.open(
        "/route/pay?bank=alipay&orderNo=" + this.order.orderNo,
        "_blank"
      );
    },
    gotoRefund(orderNO) {
      //   /order/refund/:orderNo
      this.$router.push({
        path: "/order/refund/" + orderNO,
        query: { t: new Date().getTime() },
      });
      //this.refundVisible = true;
    },
    endorse() {
      this.$alert(
        '如需改签，请拨打湖南航空客服热线<span style="color:#d70039">4008337777</span>为您服务。',
        {
          dangerouslyUseHTMLString: true,
          confirmButtonText: "我知道了",
        }
      );
    },
    gotoMember() {
      //   /order/refund/:orderNo
      this.$router.push({
        path: "/member/orderlist",
        query: { t: new Date().getTime() },
      });
    },
    orderDetailHandler() {
      var request = { t: this.orderNo };
      var that=this;
      orderDetail(request).then((res) => {
        this.loading = false;
        if (res.success) {
          console.log(res);
          var orderData = res.data;
          for (            var segIndex = 0;            segIndex < orderData.segments.length;            segIndex++
          ) {
            orderData.segments[segIndex].checked = false;
          }

          for (
            var passIndex = 0;            passIndex < orderData.passengers.length;            passIndex++          ) {
            orderData.passengers[passIndex].checked = false;
          }

          that.order = orderData;

          this.showOrder();
        } else {
          this.flights = [];
        }
      });
    },
    showOrder() {
      var adtCount = 0,
        chdCount = 0,
        infCount = 0;
      for (var i = 0; i < this.order.passengers.length; i++) {
        if (this.order.passengers[i].type == "ADT") {
          adtCount++;
        } else if (this.order.passengers[i].type == "CHD") {
          chdCount++;
        } else if (this.order.passengers[i].type == "INF") {
          infCount++;
        }

        console.log(this.order.passengers[i].tickets[0].actualStatus);
        if (
          this.order.passengers[i].tickets[0].actualStatus == "OPEN_FOR_USE" ||
          this.order.passengers[i].tickets[0].ticketStatus == "OPENFORUSE"
        ) {
          this.showRefund = true;
          console.log(this.order.passengers[i].tickets[0].actualStatus);
        }
      }

      console.log(adtCount + " " + chdCount + " " + infCount);

      if (adtCount > 0) {
        var adtFare = {
          title: "成人",
          price: this.order.adultTotalPrice / adtCount,
          passCount: adtCount,
          tax: this.order.adultTotalTax / adtCount,
        };
        this.fareData.push(adtFare);
      }

      if (chdCount > 0) {
        var chdFare = {
          title: "儿童",
          price: this.order.childTotalPrice / chdCount,
          passCount: chdCount,
          tax: this.order.childTotalTax / chdCount,
        };
        this.fareData.push(chdFare);
      }

      if (infCount > 0) {
        var infFare = {
          title: "婴儿",
          price: this.order.infantTotalPrice / infCount,
          passCount: infCount,
          tax: this.order.infantTotalTax / infCount,
        };
        this.fareData.push(infFare);
      }
      this.payIntevel();
    },
    formatDuring(mss) {
      var minutes = parseInt((mss % (1000 * 60 * 60)) / (1000 * 60));
      var seconds = (mss % (1000 * 60)) / 1000;
      seconds = parseInt(seconds);
      return minutes + " 分 " + seconds + " 秒 ";
    },
    diffMinutes(deptDate, arrDate) {
      return moment(arrDate).diff(moment(deptDate), "minutes");
    },
    overRunDays(deptDate, arrDate) {
      if (
        moment(deptDate).format("YYYY-MM-DD") ==
        moment(arrDate).format("YYYY-MM-DD")
      ) {
        return "";
      } else {
        return "(+1)";
      }
    },
    payIntevel() {
      var that = this;
      setInterval(function () {
        var remainMs = that.order.payTimeLimit - new Date().getTime();
        if (remainMs <= 0) {
          return;
        }
        that.intervelTime = that.formatDuring(remainMs);
      }, 1000);
    },
    showDetail() {
      this.detailVisible = !this.detailVisible;
    },
    constraintChange(val){
      this.refundFeeRequest.subRefundType = val;
      this.refundFeeRequest.refundReason = "";
    },
    transSubType(val){
      let types = [{val:'CANCEL', label: '取消'}, 
      {val:'DELAY', label: '延误'}, 
      {val:'ILL', label: '病退'}, 
      {val:'EPIDEMIC', label: '疫情'}, 
      {val:'OTHER', label: '其他'}]
      return types.filter(v=>v.val == val)[0].label
    }
  },
};
</script>
  <style >
.next-step {
  width: 100%;
  height: 53px;
  border: 0;
  background-color: #d70039;
  color: #fff;
  font-size: 16px;
  margin: 42px 0 53px;
}
.next-step:active {
  background-color: #d70039;
}
.add-passenger-tips {
  font-size: 14px;
  color: #9d9d9d;
}
.add-passenger-tips span {
  color: orange;
}
.add-wrapper {
  display: flex;
  align-items: center;
}
.add-passenger {
  margin-right: 10px;
  background-color: #d70039;
  width: 163px;
  height: 43px;
  color: #fff;
  border: 0;
  cursor: pointer;
}
.add-passenger:active {
  background-color: #d70039;
  border-color: #d70039;
}
.save-often .el-checkbox__label {
  font-size: 12px;
  color: #bc8b0d !important;
}
.save-often .el-checkbox__input.is-checked .el-checkbox__inner,
.save-often .el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: #bc8b0d !important;
  border-color: #bc8b0d !important;
}
.save-often .el-checkbox__inner:hover {
  border-color: #bc8b0d !important;
}
.save-often .el-checkbox__input.is-focus .el-checkbox__inner {
  border-color: #bc8b0d !important;
}
.icon-question {
  font-size: 13px;
}
.form-label {
  height: 28px;
  line-height: 28px;
  font-size: 14px;
  font-weight: 700;
  color: #717070;
}
.passenger-input-info .el-form-item__label {
  padding-bottom: 0;
}
.passenger-input-info .el-form-item {
  margin-bottom: 10px;
}
.passenger-input-info .el-input__inner {
  border-radius: 0 !important;
}
.passenger .el-checkbox__input.is-checked .el-checkbox__inner,
.passenger .el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: #e34c4c;
  border-color: #e34c4c;
}
.el-checkbox__label {
  padding-left: 5px;
}
.el-checkbox__input.is-checked + .el-checkbox__label {
  color: #e34c4c;
}
.el-checkbox__inner:hover {
  border-color: #e34c4c;
}
.el-checkbox__input.is-focus .el-checkbox__inner {
  border-color: #e34c4c;
}
</style>
  <style scoped lang="less">
.fee-panel-body .footer {
  padding: 0 26px;
  height: 60px;
  font-weight: 700;
  line-height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.fee-panel-body .footer span:nth-child(2) {
  font-size: 18px;
  color: #d70039;
}
h4 {
  height: 34px;
  font-size: 14px;
  font-weight: 700;
  line-height: 34px;
  color: #4d4e4e;
}
.noBefore {
  padding: 16px 0 0 10px;
  border-top: 1px solid #bcbcbd;
}
.noBefore::before {
  content: none;
  width: 0 !important;
}
.fee-panel-group ul li {
  height: 26px;
  font-size: 14px;
  font-weight: 400;
  line-height: 26px;
  color: #4d4e4e;
  text-align: left;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
/* .fee-panel-group ul::before {
  position: absolute;
  top: 6px;
  left: 0;
  bottom: 6px;
  width: 2px;
  content: "";
  background-color: #d8d8d8;
} */
.fee-panel-group ul {
  position: relative;
  padding: 10px;
}
.fee-panel-group > div {
  margin-bottom: 10px;
}
.fee-panel-group {
  padding-left: 10px;
}
.fee-panel__inner {
  // border: 1px solid #eae7e7;
}
.fee-panel-content {
  padding: 16px;
  background-color: #fff;
}
.fee-panel-body {
  max-height: 485px;
  overflow-y: auto;
  background-color: #f8f8f8;
}
.fee-panel-header {
  height: 60px;
  line-height: 60px;
  color: #4d4e4e;
  font-size: 16px;
  font-weight: 700;
  background-color: #f8f8f8;
  border-bottom: 1px solid #ededed;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
}
.fee-panel-top {
  display: flex;
  align-items: center;
  margin-top: 10px;
  padding: 0 10px;
  font-size: 14px;
  img {
    margin-right: 10px;
  }

  span {
    margin-right: 10px;
  }
}
.tips-text {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.65);
}
.passenger-input-info .el-input,
.passenger-input-info .el-select {
  width: 287px;
}
.no-padding {
  padding: 0 16px !important;
}
.form-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.form-title div:first-child {
  font-size: 14px;
  font-weight: 700;
  line-height: 1.5;
  color: #b2b2b2;
  text-align: left;
}
.form-title div:nth-child(2) {
  margin: 0 4px;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.5;
  color: #9d9d9d;
  text-align: center;
  cursor: pointer;
}
.check-item {
  height: 32px;
  line-height: 32px;
}
.passenger-info {
  margin-bottom: 16px;
  background-color: #fff;
  padding: 8px 0;
  padding-left: 5%;
  line-height: 30px;
  position: relative;
  /* border: 1px solid #eae7e7; */
}
.booked-ticket + .booked-ticket {
  margin-top: 20px;
}
.booked-flight-rule {
  width: 15%;
}
.booked-flight-rule span {
  width: 80px;
  font-size: 12px;
  font-weight: 400;
  line-height: 25px;
  color: #e34c4c;
  text-align: center;
  background-color: #fff;
  border: 1px solid #e34c4c;
  border-radius: 2px;
  transition: all, 0.68s;
  display: inline-block;
  margin: 0;
  padding: 0;
  height: 25px;
}
.booked-flight-cabin {
  width: 15%;
}
.booked-flight-cabin span:first-child {
  margin-right: 8px;
}
.booked-flight-arr {
  width: 20%;
}
.booked-flight-arr p:first-child {
  margin-top: 10px;
  height: 25px;
  font-size: 18px;
  font-weight: 700;
  line-height: 25px;
}
.booked-flight-arr p:nth-child(2) {
  height: 20px;
  line-height: 20px;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.booked-flight-arr p:last-child {
  height: 17px;
  font-size: 12px;
  line-height: 17px;
}
.booked-flight-duration p:first-child {
  margin-top: 23px;
  height: 17px;
  line-height: 17px;
}
.booked-flight-duration p:nth-child(2) {
  line-height: 15px;
}
.booked-flight-duration p:last-child {
  height: 17px;
  line-height: 17px;
}
.booked-flight-duration {
  width: 15%;
  padding: 0 10px;
  font-size: 12px;
  text-align: center;
}
.booked-flight-dep {
  text-align: center;
  margin-top: 20px;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.booked-flight-dep p:first-child {
  margin-top: 10px;
  height: 25px;
  font-size: 18px;
  font-weight: 700;
  line-height: 25px;
}
.booked-flight-dep p:nth-child(2) {
  height: 20px;
  line-height: 20px;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  -ms-text-overflow: ellipsis;
  text-overflow: ellipsis;
}
.booked-flight-dep p:last-child {
  height: 17px;
  font-size: 12px;
  line-height: 17px;
}
.qucheng {
  margin-left: 4px;
  color: #fff;
}
.booked-ticket-panel > div {
  line-height: 85px;
}

.flight-main-item p:first-child {
  height: 22px;
  line-height: 22px;
  margin-top: 15px;
  width: 200px;
  text-align: center;
}

.flight-main-item p:nth-child(2) {
  height: 15px;
  line-height: 15px;
  text-align: center;
  margin-top: 10px;
}

.flight-main-item p:nth-child(3) {
  height: 17px;
  font-size: 12px;
  line-height: 17px;
}

.during {
  position: relative;
  padding-top: 26px;
  width: 260px;
}

.during-line {
  width: 100%;
  height: 1px;
  background: #bfbfbf;
  display: block;
  margin-bottom: -6px;
}

.during-air {
  margin-top: -10px;
  width: 32px;
  height: 32px;
}

.booked-flight-type {
  color: #fff;
  background-color: #d70039;
  border: 1px solid #d31a1a;
  width: 15%;
  text-align: center;
  height: 30px;
  line-height: 30px;
  font-size: 12px !important;
  border-radius: 0 5px 5px 0;
}

.booked-ticket-panel {
  background-color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  min-height: 130px;
  position: relative;
  padding: 0 10px;
}
.booked-ticket-body {
  // padding: 0 18px;
  font-size: 14px;
  .tip{
    color: #333;
    padding: 0 18px;

  }
}
.passenger {
  // margin-top: 20px;
  display: flex;
  justify-content: space-between;
}
.info-panel {
  width: 100%;
  position: relative;
}
.fee-panel {
  width: 428px;
}
.booked-ticket {
  background: #f8f8f8;
  border: 1px solid #ededed;
  border-radius: 2px;
}
.booked-title {
  height: 42px;
  box-sizing: border-box;
  padding: 0 18px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.title {
  font-weight: 700;
  color: #4d4e4e;
  font-size: 16px;
}
.research {
  padding: 0;
  width: 90px;
  height: 26px;
  font-size: 12px;
  font-weight: 400;
  line-height: 26px;
  color: #fff;
  text-align: center;
  background-color: #d70039;
  border-color: #ff0505;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}
.booked-ticket {
  background: #ffffff;
  border: 1px solid #ededed;
  border-radius: 10px;
}
.item-title {
  color: #929a9f;
  font-size: 14px;
  width: 70px;
  display: inline-block;
}
.item-index {
  color: #929a9f;
  font-size: 16px;
  font-style: italic;
  margin: 0 10px;
  display: inline-block;
}
.item-value {
  color: #515457;
  font-size: 14px;
  display: inline-block;
  margin-right: 10px;
}
.order-price {
  font-size: 24px;
  color: #d70039;
  margin: 0 10px;
}
.order-drop {
  width: 12px;
  height: 10px;
  margin-left: 10px;
}
.detail-content {
  position: absolute;
  width: 250px;
  /* height: 200px; */
  border-radius: 5px;
  border: 1px solid #ededed;
  background: #ffffff;
  z-index: 10000;
  padding: 10px;
}
.footer {
  text-align: right;
  font-size: 24px !important;
  border-top: 1px solid #ededed;
  color: #d70039;
}
.book-panel {
  width: 100px;
  position: absolute;
  display: flex;
  right: 10px;
  div {
    width: 50px;
    background-color: #d70039 !important;
    color: #ffffff;
    text-align: center;
    border-radius: 5px;
    cursor: pointer;
    margin-left: 10px;
  }
}
.type-radio{
  display: flex;
  justify-content: left;
  align-items: center;
  width: 300px;
  padding-left: 20px;
}
.order-pass-item {
  display: flex;
  align-items: center;
  position: relative;
  width: 530px;
  flex-wrap: wrap;
  .ticketStatus{
    display: inline;    padding: 3px;    margin-left: 10px;
    text-align: center;
    color: #d70039;
    border-radius: 5px;
    border: 1px solid #d70039;
  }
  & > div {
    width: 260px;
  }
  &-check {
    position: absolute;
    right: 20px;
  }
}
</style>